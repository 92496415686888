import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, switchMap, take } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { SilentRequest } from '@azure/msal-browser';
import { environments } from '../environments/environments';

@Injectable({
  providedIn: 'root'
})
export class HttpRequesterService {
  private silent: SilentRequest = {
    scopes: environments.azureAdB2C.scopes
  };

  constructor(private http: HttpClient, private msalService: MsalService) { }

  get(url: string): Observable<any> {
    return this.getSilentToken()
      .pipe(switchMap((silentToken: any) => {
        if (silentToken) {
          return this.http.get<any>(url, {
            headers: new HttpHeaders({
              Authorization: silentToken.token
            })
          })
            .pipe(take(1));
        } else {
          return this.msalService.instance.logout();
        }
      }));
  }

  post(url: string, params: any): Observable<any> {
    return this.getSilentToken()
      .pipe(switchMap((silentToken: any) => {
        if (silentToken) {
          return this.http.post<any>(url, params, {
            headers: this.getHeaders(silentToken)
          })
        } else {
          return this.msalService.instance.logout();
        }
      }));
  }

  patch(url: string, params: any): Observable<any> {
    return this.getSilentToken()
      .pipe(switchMap((silentToken: any) => {
        if (silentToken) {
          return this.http.patch<any>(url, params, {
            headers: this.getHeaders(silentToken)
          })
        } else {
          return this.msalService.instance.logout();
        }
      }));
  }

  put(url: string, params: any): Observable<any> {
    return this.getSilentToken()
      .pipe(switchMap((silentToken: any) => {
        if (silentToken) {
          return this.http.put<any>(url, params, {
            headers: this.getHeaders(silentToken)
          })
        } else {
          return this.msalService.instance.logout();
        }
      }));
  }

  delete(url: string): Observable<any> {
    return this.getSilentToken()
      .pipe(switchMap((silentToken: any) => {
        return this.http.delete<any>(url, {
          headers: this.getHeaders(silentToken)
        })
      }));
  }

  private getHeaders(silentToken: any): HttpHeaders {
    return new HttpHeaders({
      Authorization: silentToken.token
    });
  }

  private getSilentToken() {
    return this.msalService.acquireTokenSilent(this.silent)
      .pipe(map((silentToken: any) => {
        const expireDue: Date | string = new Date(silentToken.expiresOn);
        const today: Date | string = new Date();

        if (today.getTime() > expireDue.getTime()) {
          return null
        }

        return {
          token: `Bearer ${silentToken.accessToken}`
        };
      }))
      .pipe(catchError(() => {
        return of(null);
      }));
  }
}
