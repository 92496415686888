export enum ProofOfLifeEnum {
  expired = 1,
}

export enum ProofOfLifeFrequency
{
    Monthly = 1,
    Quarterly = 3,
    Semiannual = 6,
    Annual = 12
}
