import { Injectable } from '@angular/core';
import { environments } from '../environments/environments';
import { Observable } from 'rxjs';
import { HttpRequesterService } from './http-requester.service';
import { IVaultRevisions } from '../assets/interfaces/IVaultRevisions';

@Injectable({
  providedIn: 'root'
})
export class VaultService {
  private apiUrl: string = environments.api;

  constructor(private http: HttpRequesterService) { }

  getStorage(): Observable<any> {
    return this.http.get(`${this.apiUrl}/v1/storage`);
  }

  getVault() {
    return this.http.get(`${this.apiUrl}/v1/account/wills`);
  }

  generateVault() {
    return this.http.post(`${this.apiUrl}/v1/account/wills`, {});
  }

  getStatusValidation(identifier: string) {
    return this.http.get(`${this.apiUrl}/v1/vault/getStatusCheckVault?identifier=${identifier}`);
  }

  getVaultRevisions(): Observable<IVaultRevisions[]> {
    return this.http.get(`${this.apiUrl}/v1/account/vaultRevisions`);
  }

  revertVersion(version: IVaultRevisions): Observable<any> {
    return this.http.post(`${this.apiUrl}/v1/account/vaultRevisions/revert`, { version });
  }
}
