import { HttpRequesterService } from './http-requester.service';
import { IPlanUpdate, IPlans } from '../assets/interfaces/IPlans';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environments } from '../environments/environments';
import { Observable, take } from 'rxjs';
import { PlanTypeEnum } from '../assets/enum/PlanTypeEnum';


@Injectable({
  providedIn: 'root'
})
export class PlansService {
  private apiUrl: string = environments.api;

  constructor(private http: HttpClient, private httpResquest: HttpRequesterService) { }

  getPlan(): Observable<IPlans[]> {
    return this.http.get<IPlans[]>(`${this.apiUrl}/v1/Plan`)
      .pipe(take(1));
  }

  getPlanById(planType: PlanTypeEnum): Observable<IPlans[]> {
    return this.http.get<IPlans[]>(`${this.apiUrl}/v1/plan?planType=${planType}`)
      .pipe(take(1));
  }

  planUpdate(input: IPlanUpdate) {
    return this.httpResquest.post(`${this.apiUrl}/v1/subscription/update`, input);
  }

  planRenew(input: IPlanUpdate) {
    return this.httpResquest.post(`${this.apiUrl}/v1/subscription/updatePayment`, input);
  }

  getPlanByAccountId(id: number) {
    return this.httpResquest.get(`${this.apiUrl}/v1/subscription/GetPlanById?accountId=${id}`);
  }

  cancelPlan(id: number) {
    return this.httpResquest.post(`${this.apiUrl}/v1/payment/PlanCancel?accountId=${id}`, {});
  }
}
