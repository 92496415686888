import { TranslateService } from '@ngx-translate/core';
import { IAccount, IBeneficiaries, IExecutor, IExternal, IPets, IRecords } from '../assets/interfaces/IAccount';
import { IChildrens } from '../assets/interfaces/IChildrens';
import { EventEmitter, Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  childrenEdit: EventEmitter<IChildrens> = new EventEmitter();
  executorEdit: EventEmitter<IExecutor> = new EventEmitter();
  recipientEdit: EventEmitter<IBeneficiaries> = new EventEmitter();
  petsEdit: EventEmitter<IPets> = new EventEmitter();
  recordsEdit: EventEmitter<IRecords> = new EventEmitter();
  externalsEdit: EventEmitter<IExternal> = new EventEmitter();
  proofOfLifeValidation: EventEmitter<IAccount> = new EventEmitter();
  HealthModal: EventEmitter<any> = new EventEmitter();
  AssetsModal: EventEmitter<any> = new EventEmitter();

  hasVideoOnStorage: EventEmitter<any> = new EventEmitter();

  constructor(private translate: TranslateService, private router: Router) { }

  callSuccessModal(text: string, confirmBtn: string) {
    forkJoin({
      title: this.translate.get('gd.modals.success'),
      text: this.translate.get(text),
      btnConfirm: this.translate.get(confirmBtn),
    })
      .subscribe(({ title, text, btnConfirm }: any) => {
        Swal.fire({
          title: title,
          html: `
          <div class="row">
            <div class="col-md-12">
              <img src="./../../../assets/img/confirmed.png"></img>
            </div>
            <div class="col-md-12">
              ${text}
            </div>
          </div>`,
          confirmButtonText: btnConfirm,
          customClass: {
            title: 'simple-modal title',
            actions: 'simple-modal action',
            htmlContainer: 'simple-modal body',
            confirmButton: 'gd.modals.done',
            container: 'simple-modal container'
          },
        })
      });
  }
  callErrorModal(text: string, confirmBtn: string) {
    forkJoin({
      text: this.translate.get(text),
      btnConfirm: this.translate.get(confirmBtn),
    })
      .subscribe(({ text, btnConfirm }: any) => {
        Swal.fire({
          title: 'Ops...',
          html: `
          <div class="row">
            <div class="col-md-12">
              <img src="./../../../assets/img/error.png"></img>
            </div>
            <div class="col-md-12">
              ${text}
            </div>
          </div>`,
          confirmButtonText: btnConfirm,
          customClass: {
            title: 'simple-modal title',
            actions: 'simple-modal action',
            htmlContainer: 'simple-modal body',
            confirmButton: 'gd.modals.done',
            container: 'simple-modal container'
          },
        })
      });
  }
  callConfirmCancel() {
    forkJoin({
      title: this.translate.get('gd.modals.canceled'),
      text: this.translate.get('gd.modals.confirmed-cancel'),
      btnCancel: this.translate.get('gd.button.cancel'),
      btnConfirm: this.translate.get('gd.button.confirm'),
    })
      .subscribe(({ title, text, btnCancel, btnConfirm }: any) => {
        Swal.fire({
          title: title,
          html: `
          <div class="row">
            <div class="col-md-12">
              <img src="./../../../assets/img/canceled.png"></img>
            </div>
            <div class="col-md-12">
              ${text}
            </div>
          </div>`,
          confirmButtonText: btnConfirm,
          customClass: {
            title: 'simple-modal title',
            actions: 'simple-modal action',
            htmlContainer: 'simple-modal body',
            confirmButton: 'gd.modals.done',
            container: 'simple-modal container'
          },
        });
      });
  }
  callCheckoutSuccessModal(text: string) {
    forkJoin({
      title: this.translate.get('gd.modals.success'),
      text: this.translate.get(text),
      btnConfirm: this.translate.get('gd.modals.done'),
    })
      .subscribe(({ title, text, btnConfirm }: any) => {
        Swal.fire({
          title: title,
          html: `
          <div class="row">
            <div class="col-md-12">
              <img src="./../../../assets/img/confirmed.png"></img>
            </div>
            <div class="col-md-12">
              ${text}
            </div>
          </div>`,
          confirmButtonText: btnConfirm,
          customClass: {
            title: 'simple-modal title',
            actions: 'simple-modal action',
            htmlContainer: 'simple-modal body',
            confirmButton: 'gd.modals.done',
            container: 'simple-modal container'
          },
        }).then((data: any) => {
          if (data.isConfirmed) {
            this.router.navigate(['home']);
          }
        });
      });
  }

}
