import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { GuardaDigitalLoaderService } from './components/guarda-digital-loader/guarda-digital-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { MsalService } from '@azure/msal-angular';
import { environments } from '../environments/environments';
import { Subject } from 'rxjs';
import { isIE } from '../assets/configs/auth-config';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonService } from '../services/common.service';

const { version: version } = require('./../../package.json');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewChecked {
  private readonly _destroying$ = new Subject<void>();
  sysVersion: string = version;
  isLoading: boolean = false;

  constructor(
    private authService: MsalService,
    private loaderService: GuardaDigitalLoaderService,
    private commonService: CommonService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.loaderService.loading
      .subscribe((isLoading: boolean) => {
        if (isLoading) {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      });

    this.translate.setDefaultLang('pt_BR');

    this.msalInitializar();
  }

  ngAfterViewChecked(): void {
    this.loaderService.loading.subscribe((isLoading: boolean) => {
      this.isLoading = isLoading;
    });
  }

  logout() {
    this.authService.instance.disableAccountStorageEvents();
    this.authService.instance.clearCache();
    localStorage.clear();

    this.authService.logoutRedirect({
      postLogoutRedirectUri: environments.azureAdB2C.redirectUri
    });
  }

  changePassword() {
    const resetPassword = {
      scopes: [],
      authority: environments.azureAdB2C.passReset,
      prompt: 'login',
    };

    if (isIE) {
      this.authService.loginRedirect(resetPassword);
    } else {
      this.authService.loginPopup(resetPassword);
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  private msalInitializar() {
    this.authService.handleRedirectObservable()
      .subscribe({
        next: (result: any) => {
          const accounts = this.authService.instance.getAllAccounts();

          if (accounts.length > 0) {
            this.checkAndSetActiveAccount();
          } else {
            this.authService.instance.loginRedirect();
          }
        },
        error: (error: HttpErrorResponse) => {
          console.log(error)
        }
      });
  }

  private checkAndSetActiveAccount() {
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);

      localStorage.setItem('UsAcDt', this.commonService.encrypt(accounts[0]));
    } else if (!activeAccount) {
      this.logout();
    } else {
      localStorage.setItem('UsAcDt', this.commonService.encrypt(activeAccount));
    }
  }
}
