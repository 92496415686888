import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable, forkJoin, map, switchMap } from "rxjs";
import { IPayments } from "../interfaces/IPayments";
import { AccountService } from "../../services/account.service";
import { PaymentsService } from "../../services/payments.service";
import { GuardaDigitalLoaderService } from "../../app/components/guarda-digital-loader/guarda-digital-loader.service";
import { IAccount } from "../interfaces/IAccount";
import { PaymentEnum } from "../enum/PaymentEnum";
import { MsalService } from "@azure/msal-angular";
import { ProofOfLifeEnum } from "../enum/ProofOfLifeEnum";
import { ModalService } from "../../services/modal.service";
import { WhiteLabelService } from "../../services/white-label.service";

@Injectable({
  providedIn: "root",
})

export class PaymentGuard implements CanActivate {
  user: IAccount | null = null;

  constructor(
    private router: Router,
    private authService: MsalService,
    private accountService: AccountService,
    private paymentsService: PaymentsService,
    private whiteLabelService: WhiteLabelService,
    private modalService: ModalService,
    private loaderService: GuardaDigitalLoaderService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {

    this.loaderService.show();

    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }

    return this.accountService.getAccount()
      .pipe(switchMap((user: IAccount) => {
        this.accountService.user.emit(user);

        if (user.partnerThems && user.partnerThems?.length > 0) {
          this.whiteLabelService.setPartnerWhiteLabelColor(user.partnerThems[0].color, user.partnerThems[1].color, user.partnerThems[2].color);
        }

        const today: Date = new Date();
        const expired: Date = new Date(user.proofOfLifeExpiry);

        if (user.status === ProofOfLifeEnum.expired) {
          if (today > expired) {
            this.modalService.proofOfLifeValidation.emit(user);
          }
        }

        return this.paymentsService.getPaymentStatus(user.accountId);
      }))
      .pipe(map((paymentData: IPayments) => {

        if (paymentData.status === 2 || paymentData.isConfirmed) {
          this.paymentsService.hasPaymentExpired.emit(paymentData.isConfirmed);

          if (paymentData.status === PaymentEnum.canceled) {
            this.loaderService.hide();
            this.router.navigate(['inactive']);
            return false;
          }

          const expirationDate: Date = new Date(paymentData.dateSituation);
          const currentDate: Date = new Date();

          if (currentDate >= expirationDate) {
            this.loaderService.hide();
            this.router.navigate(['payments']);
            return false;
          }
        }

        this.loaderService.hide();
        return true;
      }));
  }
}


