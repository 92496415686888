import Swal from 'sweetalert2';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, map, take } from 'rxjs';
import { IViaCep } from '../assets/interfaces/IViaCep';
import { HttpClient } from '@angular/common/http';
import { environments } from '../environments/environments';
import { TranslateService } from '@ngx-translate/core';
import { NzI18nService, en_US, es_ES, pt_BR } from 'ng-zorro-antd/i18n';
import * as CryptoJS from 'crypto-js';
import { ISerpro } from '../assets/interfaces/ISerpro';

const ToastObj = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true
});

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private viaCepUrl: string = environments.viacepApi;
  private serpro: string = environments.serproApi;
  private COOKIE_PATH: string = environments.COOKIE_PATH;
  private COOKIE_EXPIRE_TIME: number = environments.COOKIE_EXPIRE_TIME;
  private langSelect: string = 'BR';
  private secretKey = environments.securityKey;

  constructor(
    private router: Router,
    private http: HttpClient,
    private translate: TranslateService,
    private i18n: NzI18nService
  ) { }

  goBack(path: string[]) {
    this.router.navigate(path);
  }

  getAddressByViaCep(zipeCode: string): Observable<IViaCep> {
    return this.http.get<IViaCep>(`${this.viaCepUrl}/${zipeCode}/json`)
      .pipe(take(1));
  }

  getCompanyDataByDocument(document: string): Observable<ISerpro> {
    return this.http.jsonp<ISerpro>(`${this.serpro}/cnpj/${document}?callback=JSONP_CALLBACK`, 'callback');
  }

  getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  deleteCookie(name: string) {
    document.cookie = `${name}=${''}; Max-Age=0`;
  }

  setCookie(
    name: string,
    value: string,
  ) {
    let d: Date = new Date();

    d.setTime(d.getTime() + this.COOKIE_EXPIRE_TIME * 24 * 60 * 60 * 1000);

    let expires: string = `expires=${d.toUTCString()}`;
    let cpath: string = this.COOKIE_PATH ? `; path=${this.COOKIE_PATH}` : '';

    document.cookie = `${name}=${value}`; `${expires}${cpath}`;
  }

  clearAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookieNome = cookies[i].split("=")[0];
      this.deleteCookie(cookieNome);
    }
  }

  ToastWarning(message: string) {
    ToastObj.fire({
      text: message,
      icon: 'warning'
    })
  }

  ToastError(message: string) {
    ToastObj.fire({
      text: message,
      icon: 'error'
    })
  }

  ToastSucess(message: string) {
    ToastObj.fire({
      text: message,
      icon: 'success'
    })
  }

  setLang(lang: string) {
    localStorage.setItem('lang', lang);
  }

  getLang() {
    return localStorage.getItem('lang');
  }

  generateCustomId() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0;
      const v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  changeLanguage(language: string) {
    switch (language) {
      case 'pt_BR':
        this.langSelect = 'BR';
        this.translate.use(language);
        this.i18n.setLocale(pt_BR);
        break;
      case 'en_US':
        this.langSelect = 'EN';
        this.translate.use(language);
        this.i18n.setLocale(en_US);
        break;
      case 'es_ES':
        this.langSelect = 'ES';
        this.translate.use(language);
        this.i18n.setLocale(es_ES);
        break;
    }

    localStorage.setItem('lang', language);
  }

  encrypt(objeto: any): string {
    const stringfyObj = JSON.stringify(objeto);
    const ciphertext = CryptoJS.AES.encrypt(stringfyObj, this.secretKey).toString();
    return ciphertext;
  }

  decrypt(ciphertext: string): any {
    const bytes = CryptoJS.AES.decrypt(ciphertext, this.secretKey);
    const encryptedObj = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(encryptedObj);
  }

  getUserLoggedInfo(): any {
    const storage: string | null = localStorage.getItem('UsAcDt');

    if (storage) {
      return this.decrypt(storage);
    } else {
      return null;
    }
  }

  downloadFileByLink(link: string, name: string) {
    const blob: Blob = new Blob([link], { type: "application/octet-stream" });
    const url: string = URL.createObjectURL(blob);

    const anchor: HTMLAnchorElement = document.createElement("a");
    anchor.href = url;
    anchor.download = name;

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }

  convertToDate(date: string): Date {
    date = date.replace(/\D/g, "");

    const day = parseInt(date.substring(0, 2), 10);
    const month = parseInt(date.substring(2, 4), 10);
    const year = parseInt(date.substring(4), 10);

    return new Date(year, month - 1, day);
  }

  isMobile() {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return true;
    }

    if (/android/i.test(userAgent)) {
      return true;
    }

    if (/webOS|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
      return true;
    }

    return false;
  }
}
