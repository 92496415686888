import { EventEmitter, Injectable } from '@angular/core';
import { HttpRequesterService } from './http-requester.service';
import { environments } from '../environments/environments';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploaderService {
  private apiUrl: string = environments.api;
  reloadFiles: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpRequesterService) { }

  saveUploadFile(file: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/v1/storage`, file);
  }

  saveVideoRecord(video: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/v1/storage/UploadVideo`, video);
  }

  getAllUploads(): Observable<any> {
    return this.http.get(`${this.apiUrl}/v1/storage`);
  }

  getUploadsByName(fileName: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/v1/storage/getFileByName/${fileName}`);
  }

  downloadFile(name: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/v1/storage/download/${name}`);
  }

  deleteFile(name: string): Observable<any> {
    return this.http.delete(`${this.apiUrl}/v1/storage/${name}`);
  }
}
