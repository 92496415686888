import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WhiteLabelService {
  setPartnerWhiteLabelColor(mainColor: string, secondaryColor: string, thirdColor: string) {
    document.documentElement.style.setProperty('--gd-main-color', mainColor);
    document.documentElement.style.setProperty('--gd-secondary-color', secondaryColor);
    document.documentElement.style.setProperty('--gd-third-color', thirdColor);
  }
}
