import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { environments } from '../../environments/environments';

export const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export const msalConfig: Configuration = {
  auth: {
    clientId: environments.azureAdB2C.clientId,
    authority: environments.azureAdB2C.auth,
    knownAuthorities: environments.azureAdB2C.knowAuth,
    redirectUri: environments.azureAdB2C.redirectUri,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE,
  },
  system: {
    loggerOptions: {
      loggerCallback: (logLevel: LogLevel, message: string) => {
        console.log(message);
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false
    }
  }
}

export const protectedResources = {
  guardaDigital: {
    endpoint: `${environments.api}`,
    scopes: environments.azureAdB2C.scopes,
  },
}

export const loginRequest = {
  scopes: []
};
