{
  "name": "guarda-digital-app",
  "version": "2024.4.0.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --ssl -o",
    "start-prod": "ng serve --ssl --configuration=production -o",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "jest --coverage",
    "coverage": "jest --coverage --coverageReporters=cobertura"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.0.0",
    "@angular/cdk": "^16.2.14",
    "@angular/cli": "^17.1.1",
    "@angular/common": "^17.0.0",
    "@angular/compiler": "^17.0.0",
    "@angular/core": "^17.0.0",
    "@angular/forms": "^17.0.0",
    "@angular/platform-browser": "^17.0.0",
    "@angular/platform-browser-dynamic": "^17.0.0",
    "@angular/router": "^17.0.0",
    "@azure/msal-angular": "^3.0.9",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@types/crypto-js": "^4.2.2",
    "crypto-js": "^4.2.0",
    "ng-antd-color-picker": "^1.1.0",
    "ng-zorro-antd": "^17.2.0",
    "ng2-currency-mask": "^13.0.3",
    "ng2-pdf-viewer": "^10.0.0",
    "ngx-mask": "^14.3.3",
    "ngx-webcam": "^0.4.1",
    "remixicon": "^4.0.1",
    "rxjs": "~7.8.0",
    "simplebar-angular": "^3.2.4",
    "sweetalert2": "^11.10.2",
    "tslib": "^2.3.0",
    "uuidv4": "^6.2.13",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-builders/jest": "^17.0.3",
    "@angular-devkit/build-angular": "^17.0.2",
    "@angular/compiler-cli": "^17.0.0",
    "@angular/localize": "^17.0.8",
    "@types/jest": "^29.5.12",
    "jasmine-core": "~5.1.0",
    "jest": "^29.7.0",
    "jest-preset-angular": "^14.0.3",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "mocha-junit-reporter": "^2.2.1",
    "typescript": "~5.2.2"
  },
  "browser": {
    "uuid": "./node_modules/uuid/dist/esm-browser/v4.js"
  },
  "jest": {
    "preset": "jest-preset-angular",
    "setupFilesAfterEnv": [
      "<rootDir>/src/setup.jest.ts"
    ],
    "testPathIgnorePatterns": [
      "<rootDir>/node_modules/",
      "<rootDir>/dist/"
    ],
    "globals": {
      "ts-jest": {
        "tsConfig": "<rootDir>/tsconfig.spec.json",
        "stringifyContentPathRegex": "\\.html$"
      }
    },
    "moduleNameMapper": {
      "uuid": "node_modules/uuid/dist/esm-browser/index.js"
    }
  }
}
