import { EventEmitter, Injectable } from '@angular/core';
import { environments } from '../environments/environments';
import { Observable } from 'rxjs';
import { IPayments } from '../assets/interfaces/IPayments';
import { HttpRequesterService } from './http-requester.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {
  private apiUrl: string = environments.api;
  hasPaymentExpired: EventEmitter<boolean> = new EventEmitter();

  constructor(private http: HttpRequesterService) { }

  getPaymentStatus(accountId: number): Observable<IPayments> {
    return this.http.get(`${this.apiUrl}/v1/Payment/PaymentStatus?accountId=${accountId}`);
  }

  getPaymentHistorie(accountId: number) {
    return this.http.get(`${this.apiUrl}/v1/Payment/PaymentHistoryUser?accountId=${accountId}`);
  }
}
