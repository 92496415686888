import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { pt_BR } from 'ng-zorro-antd/i18n';
import { DatePipe, registerLocaleData } from '@angular/common';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';

import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientJsonpModule,
  HttpClientModule,
} from '@angular/common/http';
import { GuardaDigitalLoaderModule } from './components/guarda-digital-loader/guarda-digital-loader.module';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import pt from '@angular/common/locales/pt';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { CommonService } from '../services/common.service';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import { msalConfig, protectedResources } from '../assets/configs/auth-config';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { PaymentsService } from '../services/payments.service';
import { AccountService } from '../services/account.service';
import { UploaderService } from '../services/uploader.service';
import { VaultService } from '../services/vault.service';
import { HttpRequesterService } from '../services/http-requester.service';
import { PaymentGuard } from '../assets/guards/PaymentGuard';
import { CanceledGuard } from '../assets/guards/CanceledGuard';
import { PlansService } from '../services/plans.service';
import { ProofOfLifeService } from '../services/proof-of-life.service';
import { HttpInterceptorService } from '../services/http-interceptor.service';
import { environments } from '../environments/environments';

registerLocaleData(pt);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    GuardaDigitalLoaderModule,
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    AppRoutingModule,
    MsalModule.forRoot(
      new PublicClientApplication(msalConfig),
      {
        interactionType: InteractionType.Redirect, // MSAL Guard Configuration
        authRequest: {
          scopes: protectedResources.guardaDigital.scopes,
        },
      },
      {
        interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
        protectedResourceMap: new Map([
          [
            `${protectedResources.guardaDigital.endpoint}`,
            protectedResources.guardaDigital.scopes,
          ],
        ]),
      }
    ),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    CommonService,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    PaymentsService,
    AccountService,
    UploaderService,
    VaultService,
    HttpRequesterService,
    PaymentGuard,
    CanceledGuard,
    PlansService,
    ProofOfLifeService,
    DatePipe,
    {
      provide: NZ_I18N,
      useValue: pt_BR,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, environments.translate, '');
}
