import {
  IBeneficiaries,
  IBeneficiariesDTO,
  IExecutor,
  IExecutorDTO,
  IObituary,
  IFuneral,
  IFuneralDTO,
  IPetsDTO,
  IPets,
  IRecordDTO,
  IRecords,
  IExternalDTO,
  IExternal,
  IWish,
  IInformation,
  IAccount
} from '../assets/interfaces/IAccount';
import { environments } from '../environments/environments';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { HttpRequesterService } from './http-requester.service';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private apiUrl: string = environments.api;

  user: EventEmitter<IAccount> = new EventEmitter();
  hasPaymentExpired: EventEmitter<boolean> = new EventEmitter();
  vaultStatus: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpRequesterService, private commonService: CommonService) { }

  getAccount(): Observable<IAccount> {
    return this.http.get(`${this.apiUrl}/v1/Account`)
  }

  getAccountInfo(): Observable<IInformation> {
    return this.http.get(`${this.apiUrl}/v1/Account/Info`)
      .pipe(map((ret: any) => {
        const infoList: IInformation = {
          address: ret.address,
          children: ret.persons,
          lifeInsurance: ret.lifeInsurance,
          relationshipStatus: ret.relationshipStatus.toString(),
          frequency: ret.proofOfLifeFrequency
        }

        return infoList;
      }))
  }

  patchAccountInfo(information: IInformation): Observable<any> {
    return this.http.patch(`${this.apiUrl}/v1/Account/Info`, information);
  }

  getAccountObituary(): Observable<IObituary> {
    return this.http.get(`${this.apiUrl}/v1/Account/Obituary`);
  }

  patchAccountObituary(instructions: string | null): Observable<any> {
    return this.http.patch(`${this.apiUrl}/v1/Account/Obituary`, { instructions: instructions });
  }

  getAccountWish(): Observable<IWish> {
    return this.http.get(`${this.apiUrl}/v1/Account/Wish`);
  }

  patchAccountWish(wish: IWish): Observable<any> {
    return this.http.patch(`${this.apiUrl}/v1/Account/Wish`, wish);
  }

  getAccountFuneral(): Observable<IFuneral> {
    return this.http.get(`${this.apiUrl}/v1/Account/Funeral`);
  }

  patchAccountFuneral(funeral: IFuneralDTO): Observable<any> {

    funeral.serviceType = Number(funeral.serviceType)
    funeral.type = Number(funeral.type)

    return this.http.patch(`${this.apiUrl}/v1/Account/Funeral`, funeral);
  }

  getAccountPets(): Observable<IPets[]> {
    return this.http.get(`${this.apiUrl}/v1/Account/Pets`)
      .pipe(map((ret: any[]) => {
        const pets: IPets[] = [];

        ret.forEach((pet: any) => {
          pets.push({
            breed: pet.breed,
            message: pet.message,
            name: pet.name,
            type: pet.type,
            primaryId: pet.primaryId,
            avatar: pet.avatar,
            avatarUrl: pet.avatarUrl,
            primaryPerson: {
              firstName: pet.primaryFirstName,
              middleName: pet.primaryMiddleName,
              lastName: pet.primaryLastName,
              birthDate: pet.primaryBirthDate,
              cellPhone: pet.primaryCellPhone,
              email: pet.primaryEmail,
              relationship: pet.primaryRelationship
            }
          });
        })

        return pets;
      }))
  }

  patchAccountPets(pets: IPetsDTO): Observable<any> {
    return this.http.patch(`${this.apiUrl}/v1/Account/Pets`, pets);
  }

  getAccountBeneficiaries(): Observable<IBeneficiaries[]> {
    return this.http.get(`${this.apiUrl}/v1/Account/Beneficiaries`);
  }

  patchAccountBeneficiaries(beneficiaries: IBeneficiariesDTO): Observable<any> {
    return this.http.patch(`${this.apiUrl}/v1/Account/Beneficiaries`, beneficiaries);
  }

  getAccountExecutors(): Observable<IExecutor[]> {
    return this.http.get(`${this.apiUrl}/v1/Account/Executors`)
  }

  patchAccountExecutors(executors: IExecutorDTO): Observable<any> {
    return this.http.patch(`${this.apiUrl}/v1/Account/Executors`, executors);
  }

  getAccountRecords(): Observable<IRecords[]> {
    return this.http.get(`${this.apiUrl}/v1/Account/Records`);
  }

  patchAccountRecords(records: IRecordDTO): Observable<any> {
    return this.http.patch(`${this.apiUrl}/v1/Account/Records`, records);
  }

  getAccountExternal(): Observable<IExternal[]> {
    return this.http.get(`${this.apiUrl}/v1/Account/Externals`);
  }

  patchAccountExternal(external: IExternalDTO): Observable<any> {
    return this.http.patch(`${this.apiUrl}/v1/Account/Externals`, external);
  }

  getUserData() {
    this.getAccount()
      .subscribe((usr: IAccount) => {
        this.user.emit(usr);
      });
  }

  postAiSuggest(text: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/v1/AI/chat`, text);
  }
}

