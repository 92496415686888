import { Injectable } from '@angular/core';
import { HttpRequesterService } from './http-requester.service';
import { environments } from '../environments/environments';
import { IAccount } from '../assets/interfaces/IAccount';

@Injectable({
  providedIn: 'root'
})
export class ProofOfLifeService {
  private apiUrl: string = environments.api;

  constructor(private http: HttpRequesterService) { }

  validateProofOfLife(user: IAccount) {
    return this.http.post(`${this.apiUrl}/v1/account/ConfirmationAccountProofOfLife`, {
      accountId: user.accountId,
      identifier: user.identifier,
      action: true
    })
  }
}
